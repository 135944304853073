<template>
  <div class="position-relative">
    <div class="d-flex justify-content-center p-2 wet-space-between-x-3">
      <label
        v-for="(set, key) in label"
        :key="key"
        :id="`bars-legend-${_uid}-${key}-label`"
        class="checkbox is-size-7"
        :for="`bars-legend-${_uid}-${key}-input`"
      >
        <strong class="empty-ph" :style="{
          color: visible.includes(key) ? set.color : 'var(--gray)',
        }">
          {{ set.title }}
        </strong>
        <input
          type="checkbox"
          :id="`bars-legend-${_uid}-${key}-input`"
          :value="key"
          v-model="visible"
        >
        <span
          :id="`bars-legend-${_uid}-${key}-span`"
          :style="{
            backgroundColor: visible.includes(key) ? set.color : 'var(--gray)',
          }"
        ></span>
      </label>
    </div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { Line, mixins } from 'vue-chartjs';
import Chart from 'chart.js';

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    options: Object,
    loading: Boolean,
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    tooltipCallback: {
      type: Object,
      default: undefined,
    },
  },
  mounted() {
    this.renderChart(this.calcChartData, this.calcOptions);
  },
  data() {
    return {
      visible: [],
    };
  },
  computed: {
    calcOptions() {
      const options = {
        ...this.options,
        aspectRatio: this.aspectRatio,
        scales: {
          yAxes: [{
            stacked: true,
          }],
        },
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
      };
      if (this.tooltipCallback) options.tooltips.callbacks = this.tooltipCallback;
      return options;
    },
    label() {
      const label = this.chartData.datasets?.map((ds, i) => ([
        i,
        {
          title: ds.label,
          color: ds.color,
        },
      ])) || [];
      return Object.fromEntries(label);
    },
    calcChartData() {
      const datasets = this.chartData?.datasets
        ?.filter((ds, i) => this.visible.includes(i.toString()))
        .map((ds) => ({
          ...ds,
          backgroundColor: ds.color,
          borderColor: ds.color,
        })) || [];
      return {
        ...this.chartData,
        datasets,
      };
    },
  },
  watch: {
    visible: {
      handler() {
        this.update();
      },
    },
    chartData: {
      immediate: true,
      handler() {
        /** set visilbe all datasets */
        this.visible = Object.keys(this.label);
      },
    },
  },
  methods: {
    update() {
      this.renderChart(this.calcChartData, this.calcOptions);
    },
    renderChart(data, options) {
      Chart.defaults.global.defaultFontFamily = '"Avenir Next Cyr", Arial, Helvetica, sans-serif';
      if (this.$data._chart) this.$data._chart.destroy();
      if (!this.$refs.canvas) throw new Error('Please remove the <template></template> tags from your chart component. See https://vue-chartjs.org/guide/#vue-single-file-components');
      this.$data._chart = new Chart(
        this.$refs.canvas.getContext('2d'), {
          type: 'line',
          data,
          options,
          plugins: this.$data._plugins,
        },
      );
    },
  },
};
</script>

<style>

</style>
